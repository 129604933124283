<template>
  <div>
    <new-doc-upload
      :entity-id="entityId"
      :entity-class="entityClass"
      :document-entity-type="documentEntityType"
      :on-success="onSuccessAdd"
    />
    <div class="mt-3">
      <document-list
        :entity-id="entityId"
        :entity-class="entityClass"
        :documents="documents"
      />
    </div>
  </div>
</template>

<script>
const NewDocUpload = () => import('@/components/userSections/NewDocUpload.vue');
const DocumentList = () => import('@/components/documents/DocumentList.vue');

export default {
  name: 'AttachDocuments',
  components: { DocumentList, NewDocUpload },
  props: {
    entityId: String,
    entityClass: String,
    documentEntityType: String,
    documents: Array,
    onSuccess: Function,
  },
  methods: {
    onSuccessAdd(data) {
      if (this.onSuccess) this.onSuccess(data);
    },
  },
};
</script>

<style scoped>

</style>
